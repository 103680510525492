import React from 'react';

export default ({ linkType, tileTitle }) => (
	<React.Fragment>
		{(linkType !== 'page' || (linkType === 'page' && !!tileTitle)) && (
			<span className="tile uppercase sans--14">
				{linkType === 'exhibition' && 'Exhibition'}
				{linkType === 'site' && 'SITE'}
				{linkType === 'project' && 'Project'}
				{linkType === 'anatomy' && 'Anatomy'}
				{linkType === 'queue' && 'QUEUE'}
				{linkType === 'alliance' && 'Alliance'}
				{linkType === 'artist' && 'Artist'}
				{linkType === 'page' && tileTitle && tileTitle}
			</span>
		)}
	</React.Fragment>
);
