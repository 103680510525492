import React, { useState, useEffect, useContext } from 'react';
import Context from '../context/Context';
import Reveal from '../components/Reveal';
import InfiniteScrollComponent from '../components/InfiniteScrollComponent';
import Link from '../components/Link';
import FeaturedLinkTitle from '../components/FeaturedLinkTitle';
import FeaturedLinkTile from '../components/FeaturedLinkTile';
import { SwitchTransition, Transition } from 'react-transition-group';

import cx from 'classnames';
import sanityImage from '../util/sanityImage';

const TRANSITION_DURATION = 400;

const TRANSITION_STYLES = {
	default: {
		transition: `opacity ${TRANSITION_DURATION}ms ease`,
	},
	entering: {
		opacity: 0,
	},
	entered: {
		opacity: 1,
	},
	exiting: {
		opacity: 0,
	},
	exited: {
		opacity: 0,
	},
};

export default ({ location }) => {
	const search = new URLSearchParams(location.search);
	const { searchSite, searchResults = [] } = useContext(Context);
	const searchTerm = search.get('q') && search.get('q').split(',');

	useEffect(() => {
		searchSite(searchTerm[0]);
	}, [searchTerm && searchTerm[0]]);

	return (
		<Reveal className="grid-container contained mt5 mt10--md mb5 mb10--md mb20--lg">
			<section className="reveal__slide reveal__delay--1">
				{searchTerm && (
					<h1 className="sans--24 sans--48--md sans--64--lg">
						Results for: {searchTerm}
					</h1>
				)}
				{searchResults && searchResults.length > 0 && (
					<SwitchTransition>
						<Transition
							key={searchResults}
							appear
							timeout={TRANSITION_DURATION}
						>
							{status => (
								<InfiniteScrollComponent
									className="news__list mt2 mt5--md mb2 mb5--md row df fw"
									style={{
										...TRANSITION_STYLES.default,
										...TRANSITION_STYLES[status],
									}}
								>
									{searchResults.map(
										(
											{
												contentType,
												id,
												customImage,
												slug,
												title,
												artist,
												hero,
												image,
												heroImage,
												subtitle,
												startDate,
												endDate,
											},
											index
										) => {
											const picture =
												customImage ||
												image ||
												hero ||
												heroImage;

											let destination;

											switch (contentType) {
												case 'exhibition':
													destination = `/exhibitions/${slug}`;
													break;
												case 'site':
													destination = `/exhibitions/site-${slug}`;
													break;
												case 'anatomy':
													destination = `/exhibitions/anatomy-${slug}`;
													break;
												case 'queue':
													destination = `/exhibitions/queue-${slug}`;
													break;
												case 'project':
													destination = `/projects/${slug}`;
													break;
												case 'alliance':
													destination = `/alliance/${slug}`;
													break;
												case 'artist':
													destination = `/artists/${slug}`;
													break;

												default:
													break;
											}

											return (
												<article
													key={id}
													className={cx(
														'col c6--md',
														{
															mt5: index > 0,
															'mt0--md':
																index === 1,
															'mt10--md':
																index > 1,
														}
													)}
												>
													<Link
														className="df fdc y"
														url={destination}
													>
														<picture className="db b--black image--tile x">
															<source
																srcSet={sanityImage(
																	picture.url,
																	{
																		w: 2000,
																	}
																)}
																media="(min-width: 1400px)"
															/>
															<source
																srcSet={sanityImage(
																	picture.url,
																	{
																		w: 1600,
																	}
																)}
																media="(min-width: 1200px)"
															/>
															<source
																srcSet={sanityImage(
																	picture.url,
																	{
																		w: 1400,
																	}
																)}
																media="(min-width: 1000px)"
															/>
															<source
																srcSet={sanityImage(
																	picture.url,
																	{
																		w: 1200,
																	}
																)}
																media="(min-width: 600px)"
															/>
															<img
																alt={
																	picture.alt ||
																	title ||
																	'SITE | Library Street Collective'
																}
																className="db featured-link--image y"
																src={sanityImage(
																	picture.url,
																	{
																		w: 1200,
																	}
																)}
															/>
														</picture>
														<aside className="mt2 df jcb ais">
															<FeaturedLinkTitle
																linkType={
																	contentType
																}
																title={title}
																artist={artist}
																endDate={
																	endDate
																}
																startDate={
																	startDate
																}
																subtitle={
																	subtitle
																}
															/>
															<FeaturedLinkTile
																linkType={
																	contentType
																}
															/>
														</aside>
													</Link>
												</article>
											);
										}
									)}
								</InfiniteScrollComponent>
							)}
						</Transition>
					</SwitchTransition>
				)}
			</section>
		</Reveal>
	);
};
